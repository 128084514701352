<template>
  <div class="center">
    <div v-for="(item, index) in list" :key="index + 'a'">
      <!-- <div v-if="list.length > 0"> -->
      <div v-if="item.box.length" class="show-title">
        <img :src="item.src" class="play-title" alt="" />
        <!-- <div class="triangle"></div> -->
      </div>
      <div v-if="item.box.length" class="show-box">
        <div
          class="box-item"
          v-for="(item, index) in item.box"
          :key="index"
          @click="ToOpen(item.id)"
        >
          <div class="img">
            <img class="img-bg" alt="" v-lazy="item.cover" />
            <img class="img-img" alt="" v-lazy="item.weapon_cover" />
          </div>
          <div class="good-name">{{ item.name }}</div>
          <!-- <div class="good-price"><img style="width: 0.2rem;margin-right: 0.05rem;"
              src="@/assets/images/about/user/huobi.png" alt="">{{ item.bean }}</div> -->
          <div class="good-price">
            <Cprice :price="item.bean" />
          </div>
        </div>
      </div>
      <!-- </div> -->
    </div>
  </div>
</template>

<script>
export default {
  props: {
    list: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    // 读取本地滚动条
    const a = localStorage.getItem("scroll" || 0);
    if (a) {
      //读取本地的scroll
      setTimeout(() => {
        window.scrollTo({
          top: a,
          left: 0,
          behavior: "instant",
        });
        localStorage.setItem("scroll", 0);
      }, 300);
    }
  },
  methods: {
    //开箱
    ToOpen(id) {
      // 记录当前滚动位置
      localStorage.setItem("scroll", document.documentElement.scrollTop);
      this.$router.push({
        path: "/OpeningBox",
        query: {
          id: id,
        },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.center {
  width: 3.4rem;
  margin: 0 auto;
}

.show-title {
  width: 0.9rem;
  height: 0.4rem;
  margin: 0.1rem auto 0.06rem;
  text-align: center;

  .play-title {
    height: 0.4rem;
    margin: 0 auto;
  }

  .triangle {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0.2rem 0.2rem 0 0.2rem;
    border-color: #ffffff transparent transparent transparent;
    margin: 0.2rem auto;
  }
}

.show-box {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  .box-item {
    margin-top: 0.1rem;
    width: 1.6rem;
    height: 1.9rem;
    background: url(../../../assets/images/classics/box-bg.png) no-repeat;
    background-size: 100% 100%;
    text-align: center;

    box-sizing: border-box;
    padding: 0.3rem;
    cursor: pointer;

    &:hover {
      background: url(../../../assets/images/classics/box-ac.png) no-repeat;
      background-size: 100% 100%;
    }

    .img {
      // width: 1.3rem;
      height: 1rem;
      position: relative;
      // right: 10%;

      .img-bg {
        left: 0;
        top: 0;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: 8;
      }

      .img-img {
        // left: 0.3rem;
        // top: 0.3rem;
        margin: auto;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        // width: 75%;
        height: 55%;
        z-index: 9;
      }
    }

    .good-name {
      font-size: 0.14rem;
      margin-bottom: 0.1rem;
    }

    .good-price {
      width: 0.7rem;
      margin: 0 auto;
      height: 0.2rem;
      line-height: 0.2rem;
      background: url(../../../assets/images/classics/price-btn.png) no-repeat;
      background-size: 100% 100%;
      color: #dea61f;
      font-size: 0.1rem;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}
</style>
