<template>
  <div>
    <!-- <leftFloat :tabList="tabList" @currentChange="currentChange" /> -->
    <div class="classics-mod">
      <UserHeader :propType="3">{{ headerName }}</UserHeader>
      <div class="classics-show">
        <boxList :list="HotList" v-if="current === 0" />
        <!-- <record :tableRecordList="tableRecordList" :tableData="tableData" v-if="current === 1">
        <template slot-scope="item">
          <div v-if="item.item === 'type'">1</div>
          <div v-if="item.item === 'get'">2</div>
        </template>
      </record> -->
        <record
          @rest="GetUnpackingRecord"
          :tableData="tableData"
          :tableRecordList="tableRecordList"
          v-if="current === 1"
          renderSearch
        >
          <template v-slot="scope">
            <div
              style="line-height: 0.2rem; width: 0.65rem"
              v-if="scope.item === 'created_at'"
              :class="`record-item${scope.data.lv}1`"
            >
              {{ scope.data[scope.item] }}
            </div>
            <div
              v-if="scope.item === 'get_user'"
              :class="`record-item${scope.data.lv}1`"
            >
              <div class="flex">
                <img
                  :src="scope.data.get_user.avatar"
                  class="header-img"
                  alt=""
                />
                <div
                  style="
                    width: 0.8rem;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  "
                >
                  {{ scope.data[scope.item].name }}
                </div>
              </div>
            </div>
            <div
              style="white-space: nowrap; margin-right: 0.05rem"
              v-if="scope.item === 'type'"
              :class="`record-item${scope.data.lv}1`"
            >
              {{ scope.data["type_alias"] }}
            </div>
            <div
              v-if="scope.item === 'box_name'"
              :class="`record-item${scope.data.lv}1`"
            >
              <div style="white-space: nowrap">
                {{ scope.data[scope.item] }}
              </div>
            </div>
            <div
              v-if="scope.item === 'name'"
              style="display: flex; align-items: center"
              :class="`record-item${scope.data.lv}1`"
            >
              <img
                :src="scope.data.cover"
                style="display: block; height: 0.3rem"
                alt=""
              />
              <span
                style="
                  width: 60%;
                  text-overflow: ellipsis;
                  overflow: hidden;
                  white-space: nowrap;
                "
              >
                {{ scope.data[scope.item] }}
                <span
                  style="font-size: 0.15rem"
                  v-show="scope.data.dura_alias != '无'"
                  >{{ scope.data.dura_alias }}</span
                ></span
              >
            </div>
          </template>
        </record>
        <bestFalling v-if="current === 2" />
      </div>
    </div>
  </div>
</template>

<script>
import { GetHotBox, BoxUnpackingRecord } from "@/network/api.js";
import boxList from "./components/boxList.vue";
import record from "./components/record.vue";
import bestFalling from "./components/bestFalling.vue";
import leftFloat from "@/components/left-float/index.vue";
import { tableRecordList, tableData } from "./components/recordTable";
export default {
  data() {
    return {
      current: 0,
      HotList: [],
      recordList: [],
      tabList: ["经典盲盒", "掉落记录", "十佳掉落"],
      tableData: {},
      tableRecordList,
      headerName: "经典盲盒",
    };
  },
  components: {
    leftFloat,
    boxList,
    record,
    bestFalling,
  },
  //activated
  created() {
    this.ObtainHotBox();
    this.GetUnpackingRecord();
  },
  watch: {
    $route: {
      handler(newVal) {
        if (newVal.query?.tabType) {
          this.headerName = "经典盲盒";
          if (newVal.query?.tabType == 1) {
            this.headerName = "掉落记录";
          }
          if (newVal.query?.tabType == 2) {
            this.headerName = "十佳掉落";
          }
        }
        this.currentChange(newVal.query.tabType * 1 || 0);
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    GetUnpackingRecord() {
      const params = {
        id: this.$route.query.id,
        type_id: 1,
      };
      BoxUnpackingRecord(params).then((res) => {
        this.HistoryList = res.data.data;
        this.tableData = {
          total: this.HistoryList?.total,
          list: this.HistoryList.map((item) => {
            return {
              ...item,
            };
          }),
        };
      });
    },
    currentChange(i) {
      this.current = i;
    },
    //热门宝箱
    ObtainHotBox() {
      GetHotBox().then((res) => {
        this.HotList = res.data.data;
        // this.HotList = []
        // res.data.data.map((item) => {
        //   return item.box
        // }).map(item => {
        //   this.HotList = [... this.HotList, ...item]
        // })
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.classics-mod {
  // width: 100vw;
  min-height: 100vh;
  position: relative;
  padding: 0.24rem 0.18rem;

  .classics-show {
    width: 100%;
    margin: 0 auto 0;

    .header-img {
      width: 0.2rem;
      height: 0.2rem;
      margin-right: 0.05rem;
    }
  }
}
</style>
