export const tableRecordList = [
  {
    title: "掉落时间",
    width: "27%",
    index: "created_at",
    center: "center",
    render: true,
  },
  {
    title: "玩家",
    width: "25%",
    index: "get_user",
    center: "center",
    img: {
      index: "",
      style: {},
    },
    render: true,
  },
  {
    title: "游戏类型",
    width: "20%",
    index: "type",
    center: "center",
    render: true,
  },
  {
    title: "道具渠道",
    width: "17%",
    index: "box_name",
    center: "",
    leftVal: "",
    render: true,
  },
  {
    title: "获得道具",
    width: "26%",
    index: "name",
    center: "center",
    render: true,
  },
];

export const tableData = {
  total: 15,
  list: [
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
    {
      time: "07-04:11:11:11",
      name: "玩家显示五...",
      type: 1,
      price: 2.33,
      get: "P250(纪念品)I天旱（...",
    },
  ],
};
