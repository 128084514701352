<template>
  <div class="falling-mod">
    <div class="falling-title-box">
      <img src="@/assets/images/classics/falling-price.png" alt="" />
      <div class="options-box">
        <div class="option" @click="optionChange(0)" :class="optionAc === 0 ? 'option-ac' : ''">
          今天
        </div>
        <div class="option" @click="optionChange(1)" :class="optionAc === 1 ? 'option-ac' : ''">
          昨天
        </div>
        <div class="option" @click="optionChange(2)" :class="optionAc === 2 ? 'option-ac' : ''">
          近七日
        </div>
      </div>
    </div>
    <div class="list-box1">
      <div class="list-item" v-for="(item, index) in showList" :key="index">
        <div class="good-box1">
          <div class="good-img">
            <img v-if="item.cover" style="width: 100%; height: 75%; margin-top: 0.25rem" :src="item.cover" alt="" />
          </div>
          <span>TOP{{ index + 1 }}</span>
        </div>
        <div class="price">{{ item.bean }}</div>
        <div class="name">{{ item.name }}</div>
        <div class="box-type">{{ item.box_name }}</div>
        <div class="box-time">{{ item.created_at }}</div>
        <div class="pic-name">
          <div class="pic"></div>
          <div class="name">{{ item.get_user?.name || "虚位以待" }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getTenListApi } from "@/network/api.js";
import dayjs from "dayjs";
export default {
  props: {
    type: {
      type: [String, Number],
      default: 1,
    },
    list: {
      type: Array,
      default: () => [],
    },
  },
  created() {
    this.endDate = dayjs().subtract(0, "day").format("YYYY-MM-DD");
    this.getList();
  },
  data() {
    return {
      optionAc: 0,
      startDate: "",
      endDate: "",
      showList: [],
    };
  },
  methods: {
    getList(start11, end) {
      let start_date =
        start11 || dayjs().subtract(0, "day").format("YYYY-MM-DD");
      let end_date = end || dayjs().subtract(0, "day").format("YYYY-MM-DD");
      // this.startDate = dayjs().subtract(0, 'day').format("YYYY-MM-DD")
      // this.endDate = dayjs().subtract(0, 'day').format("YYYY-MM-DD")
      const params = {
        start_date,
        end_date,
        type: this.type,
      };
      getTenListApi(params).then((res) => {
        if (res.data.data.length) {
          this.showList = res.data.data;
        } else {
          this.showList = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        }
      });
    },
    optionChange(i) {
      if (i === 0) {
        this.startDate = dayjs().subtract(0, "day").format("YYYY-MM-DD");
        this.endDate = dayjs().subtract(0, "day").format("YYYY-MM-DD");
      }
      if (i === 1) {
        this.startDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
        this.endDate = dayjs().subtract(1, "day").format("YYYY-MM-DD");
      }
      if (i === 2) {
        this.startDate = dayjs().subtract(7, "day").format("YYYY-MM-DD");
        this.endDate = dayjs().subtract(0, "day").format("YYYY-MM-DD");
      }
      this.optionAc = i;
      this.getList(this.startDate, this.endDate);
    },
  },
};
</script>
<style lang="scss" scoped>
.falling-mod {
  .falling-title-box {
    display: flex;
    justify-content: space-between;
    box-sizing: border-box;
    flex-wrap: wrap;
    // padding: 0.5rem 0.1rem;
    align-items: center;
    font-size: 0.12rem;

    img {
      width: 1.53rem;
      margin-bottom: 0.18rem;
    }

    .options-box {
      display: flex;
      width: 100%;
      margin-bottom: 0.18rem;

      .option {
        width: 0.52rem;
        height: 0.2rem;
        text-align: center;
        line-height: 0.2rem;
        color: #999;
        font-size: 0.12rem;
        font-weight: 400;
        border-radius: 0.02rem;
        background: #000000;
        box-sizing: border-box;
        border: 1px solid #277e84;
        box-shadow: inset 0px 0px 5px 2px #277e84;
        // margin-left: 0.1rem;
        cursor: pointer;
        transition: all 0.2s;
        margin-right: 0.04rem;
      }

      .option-ac {
        border: 1px solid #560000;
        box-shadow: inset 0px 0px 5px 2px #560000;
      }
    }
  }

  .list-box1 {
    width: 100%;
    display: flex;
    flex-wrap: wrap;

    .list-item {
      margin: 0 1%;
      width: 48%;
      height: 2.58rem;
      background: url(../../../assets/images/classics/falling-box.png) no-repeat;
      background-size: contain;
      overflow: hidden;
      box-sizing: border-box;
      padding-top: 0.1rem;
      text-align: center;
      font-size: 0.26rem;
      font-family: FZZongYi-M05S;
      font-weight: 400;
      color: #66ccff;
      margin-bottom: 0rem;

      &:nth-child(5n - 4) {
        margin-left: 0;
      }

      .good-box1 {
        box-sizing: border-box;
        display: flex;
        justify-content: center;
        position: relative;

        .good-img {
          width: 1rem;
          height: 1rem;
          // background: red;
          border-radius: 50%;
        }

        span {
          width: 0.4rem;
          height: 0.24rem;
          position: absolute;
          left: 0.3rem;
          bottom: -0.1rem;
          font-size: 0.17rem;
        }
      }

      .price {
        margin-top: 0.15rem;
        font-size: 0.2rem;
      }

      .name,
      .box-type {
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        // height: 0.35rem;
        overflow: visible;
      }

      .box-type {
        // margin-top: 0.1rem;
        color: #77bfec;
      }

      .box-time {
        font-size: 0.12rem;
        font-family: Microsoft YaHei;
        color: #77bfec;
      }

      .pic-name {
        margin-top: 0.1rem;
        width: 90%;
        height: 0.22rem;
        line-height: 0.22rem;
        background: #000000;
        border-radius: 0rem 0.11rem 0rem 0.11rem;
        font-size: 0.15rem;
        font-family: Microsoft YaHei;
        color: #cccccc;
        text-align: center;

        box-sizing: border-box;
        border: 0.01rem solid #004672;
        box-shadow: inset 0px 0px 5px 1px #004672;
        margin-left: auto;
        margin-right: auto;
        display: flex;
        align-items: center;
        justify-content: center;

        .pic {
          width: 0.16rem;
          height: 0.16rem;
          border-radius: 50%;
          background: #fff;
          margin-right: 0.12rem;
        }
      }
    }
  }
}
</style>
